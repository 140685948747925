import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/healthcare-pharma/*" />
    <RAC path="/zh-cn/healthcare-pharma/*" />
    <RAC path="/zh-tw/healthcare-pharma/*" />
    <RAC path="/pl/healthcare-pharma/*" />
    <RAC path="/ru/healthcare-pharma/*" />
    <RAC path="/it/healthcare-pharma/*" />
    <RAC path="/de/healthcare-pharma/*" />
    <RAC path="/es/healthcare-pharma/*" />
    <RAC path="/fr/healthcare-pharma/*" />
    <RAC path="/ar/healthcare-pharma/*" />
    <RAC path="/id/healthcare-pharma/*" />
    <RAC path="/pt/healthcare-pharma/*" />
    <RAC path="/ko/healthcare-pharma/*" />
    <DynamicNotFoundPage default />
  </Router>
)
